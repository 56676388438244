import { Link } from "@reach/router"
import { HTMLProps } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { TTheme } from "../../types"
import { generateUrl } from "../lib/urls"
import { H3 } from "./headings"
import { Typography } from "./typography"

interface ArtworkProps {
	slug: string
	title: string
	material?: string
	artistName: string
	imageSrc: string
	imageAspectRatio: number
	sold: boolean
}

export default function Artwork({
	slug,
	title,
	material,
	artistName,
	imageSrc,
	imageAspectRatio,
	className,
	sold,
}: ArtworkProps & HTMLProps<HTMLAnchorElement>) {
	return (
		<Link
			className={className}
			to={generateUrl.art(slug)}
			css={{
				textDecoration: `none`,
				marginBottom: `24px`,
				"[data-hover-target]": {
					transition: `transform 0.4s ease`,
					willChange: `transform`,
				},
				":hover [data-hover-target]": {
					transform: `translateY(0)`,
				},
				img: {
					transition: `transform 0.4s ease`,
					willChange: `transform`,
				},
				":hover img": {
					transform: `scale(1.05)`,
				},
			}}
		>
			<div
				css={(theme: TTheme) => ({
					position: `relative`,
					width: `100%`,
					height: 0,
					overflow: `hidden`,
					paddingBottom: `${100 / imageAspectRatio}%`,
				})}
			>
				<LazyLoadImage
					width={1}
					height={1}
					src={imageSrc}
					css={{
						position: `absolute`,
						top: 0,
						left: 0,
						width: `100%`,
						height: `100%`,
						zIndex: 1,
					}}
				/>
				{sold && ( // RED CIRCLE
					<div
						css={{
							position: `absolute`,
							bottom: `9px`,
							right: `9px`,
							borderRadius: `14px`,
							width: `6px`,
							height: `6px`,
							background: `#c00000`,
							zIndex: 2,
						}}
					/>
				)}
				<div
					data-hover-target={true}
					css={(theme: TTheme) => ({
						color: theme.colours.on.image,
						position: `absolute`,
						bottom: 0,
						left: 0,
						width: `100%`,
						height: `auto`,
						zIndex: 2,
						transform: `translateY(100%)`,
						padding: `${theme.spacing.m} ${theme.spacing.m}`,
						background: `black`,
					})}
				>
					<Typography
						size="m"
						css={(theme: TTheme) => ({
							color: theme.colours.on.image,
							lineHeight: `1em`,
							width: `100%`,
							textOverflow: `ellipsis`,
							overflow: `hidden`,
						})}
					>
						{artistName}
					</Typography>
					{material && (
						<Typography
							size="s"
							css={(theme: TTheme) => ({
								marginTop: theme.spacing.s,
								color: theme.colours.on.image,
								width: `100%`,
								lineHeight: `1em`,
								textOverflow: `ellipsis`,
								overflow: `hidden`,
							})}
						>
							{material}
						</Typography>
					)}
				</div>
			</div>
			<H3
				css={(theme: TTheme) => ({
					textAlign: `center`,
					marginTop: theme.spacing.xs,
				})}
			>
				{title}
			</H3>
		</Link>
	)
}
