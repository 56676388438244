import { HTMLProps } from "react"
import Masonry from "react-masonry-component"
import mq from "../theme/mq"
import styled from "../theme/styled"

export const MasonryParent = styled.div(props => ({
	margin: `0 -${props.theme.spacing.m}`,
	// helps with legacy browsers
	":after": {
		content: `''`,
		display: `block`,
		clear: `both`,
	},
}))

export const MasonryContainer = ({ children }: HTMLProps<any>) => (
	<Masonry
		options={{ horizontalOrder: true, columnWidth: ".masonry-spacer" }}
	>
		<MasonrySpacer />
		{children}
	</Masonry>
)

export const MasonryItem = styled.div<{ aspectRatio: number }>(
	({ theme, aspectRatio }) => ({
		float: `left`,
		padding: `0 ${theme.spacing.m} ${theme.spacing.xl}`,
		width: aspectRatio > 1 ? `100%` : `50%`,
		[mq.medium]: {
			width: aspectRatio > 1.4 ? `66%` : `33%`,
		},
		[mq.large]: {
			width: aspectRatio > 1.4 ? `50%` : `25%`,
		},
	})
)

export const MasonrySpacer = () => (
	<div
		className="masonry-spacer"
		css={{
			float: `left`,
			width: `50%`,
			[mq.medium]: {
				width: `33%`,
			},
			[mq.large]: {
				width: `25%`,
			},
		}}
	/>
)
