import { graphql, useStaticQuery } from "gatsby"
import groupBy from "lodash.groupby"
import React, { ChangeEvent, useCallback, useMemo, useState } from "react"
import { TTheme } from "../../types"
import { Container } from "../components/container"
import { Grid } from "../components/grid"
import { H2 } from "../components/headings"
import { Select } from "../components/select"
import { Typography } from "../components/typography"
import mq from "../theme/mq"
import styled from "../theme/styled"

type NodeItem = any["allArtworks"]["nodes"][0]

interface BrowseByArtworkArtists {
	type: `artists`
	data: {
		[artistName: string]: NodeItem[]
	}
}

interface BrowseByArtworkMaterial {
	type: `material`
	data: {
		[artistName: string]: NodeItem[]
	}
}

interface BrowseByArtworkAll {
	type: `all`
	data: NodeItem[]
}

type BrowseByArtworks =
	| BrowseByArtworkArtists
	| BrowseByArtworkAll
	| BrowseByArtworkMaterial

type BrowseByTypes = "all" | "artists" | "material"

function getSortedData({ data, sort }: { data: NodeItem[]; sort: string }) {
	if (sort === "name") {
		return data.sort((a, b) => a.title.localeCompare(b.title))
	}
	if (sort === "artist") {
		return data.sort((a, b) => a.artist.name.localeCompare(b.artist.name))
	}
	if (sort === "priceAsc") {
		return data.sort((a, b) =>
			a.price > b.price ? 1 : a.price < b.price ? -1 : 0
		)
	}
	if (sort === "priceDesc") {
		return data.sort((a, b) =>
			b.price > a.price ? 1 : b.price < a.price ? -1 : 0
		)
	}
	return data.sort((a, b) =>
		a.createdAt > b.createdAt ? 1 : a.createdAt < b.createdAt ? -1 : 0
	)
}

const StyledSelect = styled(Select)({
	textTransform: `uppercase`,
})

export default function ArtPage() {
	const {
		allArtworks: { nodes: artworks },
	} = useStaticQuery<any>(graphql`
		query ArtPage {
			allArtworks: allSanityArt {
				nodes {
					createdAt: _createdAt(difference: "seconds")
					id
					title
					material {
						name
					}
					price
					sold
					artist {
						name
						description: _rawDescription
					}
					image {
						asset {
							small: fixed(width: 400) {
								src
								aspectRatio
							}
							large: fixed(width: 800) {
								src
							}
						}
					}
					slug {
						current
					}
				}
			}
		}
	`)
	const [sort, setSort] = useState("name")
	const [browse, setBrowse] = useState<BrowseByTypes>("all")

	const browseByArtworks: BrowseByArtworks = useMemo(() => {
		if (browse === "artists") {
			return {
				type: `artists`,
				data: groupBy(artworks, artwork => artwork.artist.name),
			}
		}
		if (browse === "material") {
			return {
				type: `material`,
				data: groupBy(artworks, artwork => artwork.material.name),
			}
		}
		return { type: `all`, data: artworks }
	}, [artworks, browse])

	const sortedArtworks: NodeItem[] = useMemo(() => {
		// bail out early if type is artists or material
		if (
			browseByArtworks.type === "artists" ||
			browseByArtworks.type === "material"
		) {
			return []
		}
		// need to deconstruct after to easily let typescript know
		// what "data" is since "type !== artists" here :)
		const { data } = browseByArtworks
		return getSortedData({ data, sort })
	}, [sort, browseByArtworks.type, browseByArtworks.data])

	const onChangeBrowse = useCallback(
		(e: ChangeEvent<HTMLSelectElement>) => {
			if (sort === "artists") {
				setSort("all")
			}
			setBrowse(e.target.value as BrowseByTypes)
		},
		[sort]
	)

	const onChangeSort = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
		setSort(e.target.value)
	}, [])

	return (
		<Container
			css={(theme: TTheme) => ({
				marginTop: theme.spacing.l,
				[mq.medium]: {
					marginTop: theme.spacing.xl,
				},
				[mq.large]: {
					marginTop: theme.spacing.xxxl,
				},
			})}
		>
			<div
				css={(theme: TTheme) => ({
					display: `flex`,
					flexWrap: `wrap`,
					alignItems: `center`,
					marginBottom: theme.spacing.xxl,
					[mq.large]: {
						marginBottom: theme.spacing.xxxl,
					},
				})}
			>
				<H2
					css={(theme: TTheme) => ({
						flex: `1 0 100%`,
						lineHeight: `1em`,
						textAlign: `center`,
						marginBottom: theme.spacing.xl,
						[mq.medium]: {
							marginBottom: theme.spacing.xxl,
						},
						[mq.large]: {
							marginBottom: 0,
							textAlign: `left`,
							flex: 1,
						},
					})}
				>
					Artwork
				</H2>
				<div
					css={{
						marginLeft: `auto`,
						display: `flex`,
						flexWrap: `wrap`,
						alignItems: `center`,
					}}
				>
					<Typography
						size="xxs"
						css={(theme: TTheme) => ({
							flex: `0 1 30%`,
							[mq.medium]: {
								flex: `0 0 auto`,
								paddingRight: theme.spacing.s,
							},
						})}
					>
						Browse by
					</Typography>
					<StyledSelect
						onChange={onChangeBrowse}
						value={browse}
						css={(theme: TTheme) => ({
							flex: `0 1 50%`,
							marginLeft: `auto`,
							[mq.medium]: {
								flex: 1,
								marginLeft: 0,
							},
						})}
					>
						<option value="all">All</option>
						<option value="artists">Artists</option>
						<option value="material">Materials</option>
					</StyledSelect>
					<Typography
						size="xxs"
						css={(theme: TTheme) => ({
							flex: `0 1 30%`,
							marginTop: theme.spacing.m,
							[mq.medium]: {
								flex: `0 0 auto`,
								marginTop: 0,
								paddingRight: theme.spacing.s,
								paddingLeft: theme.spacing.xxl,
							},
						})}
					>
						Sort by
					</Typography>
					<StyledSelect
						onChange={onChangeSort}
						value={sort}
						css={(theme: TTheme) => ({
							flex: `0 1 50%`,
							marginTop: theme.spacing.m,
							marginLeft: `auto`,
							[mq.medium]: {
								flex: 1,
								marginLeft: 0,
								marginTop: 0,
							},
						})}
					>
						<option value="name">Artwork Name</option>
						<option value="date">New</option>
						{browse !== "artists" && (
							<option value="artist">Artist</option>
						)}
						<option value="priceAsc">Price Asc</option>
						<option value="priceDesc">Price Desc</option>
					</StyledSelect>
				</div>
			</div>
			{browseByArtworks.type === "artists" ||
			browseByArtworks.type === "material" ? (
				Object.entries(browseByArtworks.data)
					.sort(([a], [b]) => a.localeCompare(b))
					.map(([artist, artworks]) => (
						<Grid
							heading={artist}
							items={getSortedData({ data: artworks, sort })}
							key={artist}
						/>
					))
			) : (
				<Grid items={sortedArtworks} key="all" />
			)}
		</Container>
	)
}
