import React, { useState } from "react"
import { TTheme } from "../../types"
import Artwork from "../components/artwork"
import { DefaultButton } from "../components/button"
import { H4 } from "../components/headings"
import {
	MasonryContainer,
	MasonryItem,
	MasonryParent,
} from "../components/masonry"

interface GridProps {
	amountOfItemsPerPage?: number
	heading?: string
	items: NodeItem[]
}

type NodeItem = any

const defaultAmountOfItemsPerPage = 10

export const Grid: React.FC<GridProps> = ({
	heading,
	items,
	amountOfItemsPerPage = defaultAmountOfItemsPerPage,
}) => {
	const [displayAmount, setDisplayAmount] = useState(amountOfItemsPerPage)
	const displayAmountOfItemsPerPage = Math.min(
		items.length - displayAmount,
		amountOfItemsPerPage
	)
	return (
		<>
			{heading && (
				<H4
					css={(theme: TTheme) => ({
						margin: `0 0 ${theme.spacing.s} 0`,
						borderBottom: `1px solid ${theme.colours.primary}`,
						":not(:first-of-type)": {
							marginTop: theme.spacing.l,
						},
					})}
				>
					{heading}
				</H4>
			)}
			<MasonryParent>
				<MasonryContainer>
					{items
						.slice(0, displayAmount)
						.filter(Boolean)
						.map(artwork => (
							<MasonryItem
								aspectRatio={
									artwork.image.asset.small.aspectRatio
								}
								key={artwork.id}
							>
								<Artwork
									sold={artwork.sold}
									slug={artwork.slug.current}
									title={artwork.title}
									material={artwork.material.name}
									artistName={artwork.artist.name}
									imageSrc={
										artwork.image.asset.small.aspectRatio <
										1
											? artwork.image.asset.small.src
											: artwork.image.asset.large.src
									}
									imageAspectRatio={
										artwork.image.asset.small.aspectRatio
									}
								/>
							</MasonryItem>
						))}
				</MasonryContainer>
			</MasonryParent>
			{items.length > displayAmount && (
				<DefaultButton
					onClick={() =>
						setDisplayAmount(displayAmount + amountOfItemsPerPage)
					}
					css={(theme: TTheme) => ({
						margin: `${theme.spacing.l} auto ${theme.spacing.xxl}`,
						display: `block`,
					})}
				>
					Show {displayAmountOfItemsPerPage} more
				</DefaultButton>
			)}
		</>
	)
}
