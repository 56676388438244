import styled from "../theme/styled"

const Select = styled.select(({ theme }) => ({
	fontSize: theme.font.size.s,
	width: `100%`,
	lineHeight: `1.5em`,
	borderRadius: 0,
	borderWidth: `2px`,
	borderStyle: `solid`,
	height: `38px`,
	borderColor: theme.colours.primary,
	color: theme.colours.inverted,
	background: theme.colours.on.inverted,
	fontFamily: theme.font.family.heading,
	fontWeight: 600,
}))

export { Select }
